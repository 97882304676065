import React from 'react'
import { Link, useParams } from 'react-router-dom'
import Gemstone from './Category/Gemstone';
import OnlinePuja from './Category/OnlinePuja';

const CategoryPage = () => {
    const page = useParams().page;
    return (
        <>
            <>
                <div className="astrologers-list astromall-page">
                    <div className="container">
                        <div className="page-img-banner mt-3 pb-3">
                            <img src={`/images/page/${page}.png`} className='img-fluid' />
                        </div>
                        <div className="sub-page">
                            <div className="ui">
                                <h6><Link to={'/astromall'}>Astromall</Link></h6>
                                <h6><i class="fa fa-angle-right d-block" /></h6>
                                <h6 className='text-primary'>{page}</h6>
                            </div>
                        </div>
                        {
                            page === 'gemstone' ?
                                <Gemstone {...{ page }} />
                                :
                                page === 'online puja' ?
                                    <OnlinePuja {...{ page }} />
                                    :
                                    <>
                                        <div className="notefound mt-3 text-center">
                                            <h5 className='text-capitalize'>{page} not availabe !!</h5>
                                        </div>
                                    </>
                        }
                    </div>
                </div>
            </>
        </>
    )
}

export default CategoryPage