import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import PageBanner from '../../Utility/PageBanner'
import HoroscopeTab from '../../Utility/HoroscopeSign'
import { instance } from '../../../../api'
import { currentYearMonthDate } from '../../Utility/Utility'
import LoaderCom from '../../Utility/LoaderCom'
const Horoscope = () => {
    const page = useParams().horoscope;
    const [daysWise, setDaysWise] = useState('daily');
    const [horoscope, setHoroscope] = useState({})
    const [loading, setLoading] = useState(false)
    useEffect(() => {
        getHoroscope()
    }, [page])

    const getHoroscope = async () => {
        setLoading(true)
        try {
            const param = {
                "select_date": currentYearMonthDate(),
                "zodiacs_sign_name": page
            }
            const res = await instance.post('/horoscope', param);
            setLoading(true)
            if (res?.data?.status) {
                setHoroscope(res?.data?.data)
                setLoading(false)
            } else {
                setHoroscope({})
                setLoading(false)
            }
        } catch (error) {
            setHoroscope()
            console.log(error)
            setLoading(false)
        }
    }
    return (
        <div className="horoscope-page horoscope-section">
            <div className="container">
                <PageBanner img={'/images/page/horoscope.png'} />
                <div className="sub-page">
                    <div className="ui">
                        <h6><Link to={'#'} className='d-block'>horoscope</Link></h6>
                        <h6 className='pt-1'><i class="fa fa-angle-right d-block" /></h6>
                        <h6 className='text-primary'>{page}</h6>
                    </div>
                </div>
                <div className="row g-3 mb-3">
                    <div className="col-12">
                        <div className="bs">
                            <div className="tab-btn">
                                <Link to={'#'} className={`${daysWise === 'daily' && "active"}`} onClick={() => setDaysWise('daily')}>Daily</Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="bs">
                            {
                                loading ?
                                    <>
                                        <LoaderCom type="spokes" color="#bd0001" />
                                    </>
                                    :
                                    <>
                                        {
                                            horoscope ?
                                                <p>{horoscope.horoscope_detail}</p>
                                                :
                                                <h6 className='text-danger text-center'>There are no data to display !!</h6>
                                        }
                                    </>
                            }
                        </div>
                    </div>
                </div>
                <HoroscopeTab />
            </div>
        </div>
    )
}

export default Horoscope