import axios from 'axios';
import React, { useEffect, useState } from 'react';

const Kundali = () => {
	const [ inputData, setInputData ] = useState({
		name: '',
		gender: '',
		dob: '',
		birth_time: '',
		birth_place: '',
		birth_city: ''
	});

	useEffect(() => {
		getResponse();
	}, []);

	// '8785ba45b8a20a205fa9bd9e9d5e5520'
	const getResponse = async () => {
		try {
			const headers = {
				Authorization: `Bearer ${'8785ba45b8a20a205fa9bd9e9d5e5520'}`,
				'Custom-Header': 'application/json'
			};
			const res = await axios.post('http://api.positionstack.com/v1/forward');
			console.log(res);
		} catch (error) {
			console.log(error);
		}
	};

	const handleData = (e) => {};

	const handleSubmit = (e) => {
		e.preventDefault();
	};

	return (
		<div class="container">
			<form action="">
				<div class="header-panel">Make Free Kundli</div>
				<div class="container-fluid" id="form-innner">
					<div class="row">
						<div class="col-xl-6">
							<div class="form-group">
								<label for="">Name</label>
								<input
									type="text"
									value=""
									name="name"
									onChange={handleData}
									placeholder="Name"
									class="form-control"
									required
								/>
								<small />
							</div>
						</div>
						<div class="col-xl-6">
							<div class="form-group">
								<label for="">Gender</label>
								<select
									class="form-control"
									id="selector2"
									name="gender"
									onChange={handleData}
									required
								>
									<option>Select</option>
									<option value="male">Male</option>
									<option value="female">Female</option>
									<option value="other">Other</option>
								</select>
							</div>
						</div>
					</div>
					<div class="row">
						<div class="col-xl-6">
							<div class="form-group">
								<label for="">DOB</label>
								<input
									type="date"
									value=""
									name="dob"
									onChange={handleData}
									class="form-control"
									required
								/>
							</div>
						</div>
						<div class="col-xl-6">
							<div class="form-group">
								<label for="">Birth Time</label>
								<input
									type="time"
									value=""
									name="birth_time"
									onChange={handleData}
									class="form-control"
									required
								/>
							</div>
						</div>
					</div>
					<div class="row">
						<div class="col-xl-6">
							<div class="form-group">
								<label for="">Birth Palce</label>
								<input
									type="text"
									value=""
									name="birth_place"
									onChange={handleData}
									placeholder="Birth place"
									class="form-control"
									required
								/>
							</div>
						</div>
						<div class="col-xl-6">
							<div class="form-group">
								<label for="">Birth City</label>
								<input
									type="text"
									value=""
									name="birth_city"
									onChange={handleData}
									placeholder="Birth city"
									class="form-control"
									required
								/>
							</div>
						</div>

						<button type="submit" onClick={handleSubmit} class="">
							Generate Kundli
						</button>
					</div>
				</div>
			</form>
		</div>
	);
};

export default Kundali;
