import React from 'react'
import { Link } from 'react-router-dom'
import PageBanner from '../../Utility/PageBanner'

const Astromall = () => {
    return (
        <>
            <div className="astrologers-list astromall-page">
                <div className="container">
                    <PageBanner img={'/images/page/astromall.png'}/>
                    <div className="row g-3">
                        <div className="col-12 col-sm-6 col-md-4 col-lg-4">
                            <div className="bs">
                                <Link to='/astromall/gemstone'>
                                    <div className="astro-box">
                                        <div className="img-box">
                                            <img src="/images/astromall/gemstone.jpg" />
                                        </div>
                                        <div className="text ms-3">
                                            <h6>gemstone</h6>
                                            <span>Lorem ipsum dolor sit amet consectetur adipisicing elit. Explicabo assumenda qui vitae modi repudiandae quaerat incidunt pariatur!</span>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        </div>
                        <div className="col-12 col-sm-6 col-md-4 col-lg-4">
                            <div className="bs">
                                <Link to='/astromall/online puja'>
                                    <div className="astro-box">
                                        <div className="img-box">
                                            <img src="/images/astromall/puja.jpg" />
                                        </div>
                                        <div className="text ms-3">
                                            <h6>online puja</h6>
                                            <span>Lorem ipsum dolor sit amet consectetur adipisicing elit. Explicabo assumenda qui vitae modi repudiandae quaerat incidunt pariatur!</span>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        </div>
                        <div className="col-12 col-sm-6 col-md-4 col-lg-4">
                            <div className="bs">
                                <Link to='/astromall/books'>
                                    <div className="astro-box">
                                        <div className="img-box">
                                            <img src="/images/astromall/book.png" />
                                        </div>
                                        <div className="text ms-3">
                                            <h6>books</h6>
                                            <span>Lorem ipsum dolor sit amet consectetur adipisicing elit. Explicabo assumenda qui vitae modi repudiandae quaerat incidunt pariatur!</span>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        </div>
                        <div className="col-12 col-sm-6 col-md-4 col-lg-4">
                            <div className="bs">
                                <Link to='/astromall/rudraksha'>
                                    <div className="astro-box">
                                        <div className="img-box">
                                            <img src="/images/astromall/rudraksha.jpg" />
                                        </div>
                                        <div className="text ms-3">
                                            <h6>rudraksha</h6>
                                            <span>Lorem ipsum dolor sit amet consectetur adipisicing elit. Explicabo assumenda qui vitae modi repudiandae quaerat incidunt pariatur!</span>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Astromall