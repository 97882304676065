import React, { useContext } from 'react'
import PageBanner from '../../Utility/PageBanner'
import { useEffect } from 'react'
import { apiBaseURL } from '../../../../api'
import LoaderCom from '../../Utility/LoaderCom'
import { CommonContextData } from '../../../Context/CommonContext'

const AstrologersList = () => {
    const  {getDataFun, dataList, loading} = useContext(CommonContextData);
    useEffect(() => {
        getDataFun("/astrolist");
        
        
    }, [])

    return (
        <>
            <div className="astrologers-list">
                <div className="container">
                    <PageBanner img={'/images/page/astrologers.png'} />
                    <div className="row g-3">

                        {
                            loading ?
                                <>
                                    <LoaderCom type="spokes" color="#bd0001" />
                                </>
                                :
                                <>
                                    {
                                        dataList?.map((data, i) => (
                                            <div className="col-12 col-sm-12 col-md-6 col-lg-4">
                                                <div className="bs">
                                                    <div className="astro-box">
                                                        <div className="img-box">
                                                            <img src={data.profile ? `${apiBaseURL + "profile/" + data.profile}` : "/images/astro_logo_profile.png"} />
                                                        </div>
                                                        <div className="text ms-3">
                                                            <h6>
                                                                <div class={`spinner-grow spinner-grow-sm me-1 ${data.online_status === "online" ? "text-success" : "text-danger"} `} role="status">
                                                                    <span class="visually-hidden">Loading...</span>
                                                                </div>
                                                                {data.name}</h6>
                                                            <span>{data.graduation?.length > 25 ? data.graduation.substring(0, 25) + "..." : data.graduation}</span>
                                                            <span>{data.languages?.length > 25 ? data.languages.substring(0, 25) + "..." : data.languages}</span>
                                                            <span>Exp: {data.exp_year} Years</span>
                                                            <span> <strong>₹ {data.rate}</strong>/min</span>
                                                        </div>
                                                        <div className="verify-icon">
                                                            <img src="/images/verified.png" style={{ height: '25px' }} />
                                                        </div>
                                                        <div className="astro-btn-box">
                                                            <button><i className='fa fa-phone'></i> call</button>
                                                            <button><i className='fa fa-commenting'></i> chat</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                    }
                                </>
                        }

                    </div>
                </div>
            </div>
        </>
    )
}

export default AstrologersList