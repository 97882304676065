import React from 'react'
import { Link } from 'react-router-dom'
const Gemstone = ({page}) => {
    return (
        <>
            <div className="row g-3">
                <div className="col-12 col-sm-6 col-md-4 col-lg-4">
                    <div className="bs">
                        <Link to='/astromall/gemstone'>
                            <div className="astro-box">
                                <div className="img-box">
                                    <img src="/images/astromall/gemstone.jpg" />
                                </div>
                                <div className="text ms-3">
                                    <h6>{page}</h6>
                                    <span>Lorem ipsum dolor sit amet consectetur adipisicing elit. Explicabo assumenda qui vitae modi repudiandae quaerat incidunt pariatur!</span>
                                </div>
                            </div>
                        </Link>
                    </div>
                </div>

            </div>
        </>
    )
}

export default Gemstone