import axios from 'axios';
import React, { useState } from 'react'
import OTPInput from 'react-otp-input';
import { Link, useLocation } from 'react-router-dom'
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { toastifySuccess, } from '../Utility/Utility';


const Header = () => {

    const location = useLocation();
    const page = location.pathname
    const pathSegments = location.pathname.split('/');
    const horoscope = pathSegments[pathSegments.length - 2];
    const astromall = pathSegments[pathSegments.length - 2];
    const [loginModal, setLoginModal] = useState(false)

    // ---------------//----------

    const [value, setValue] = useState({
        contact: ''
    });
    // console.log(value,'value')
    const [otpStatus, setOtpStatus] = useState(false);
    const [otp, setOtp] = useState('');
    const [useotp, setuseotp] = useState('')
    const [otppage, setotppage] = useState(false)
    const [loginmassage, setloginmassage] = useState('')

    const handleChange = (event) => {
        const { name, value } = event.target;
        let ele = value.replace(/\D/g, '');
        if (ele.length === 10) {
            const cleaned = ele.replace(/\D/g, '');
            const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
            if (match) {
                setValue((prevState) => ({
                    ...prevState,
                    [name]: match[1] + '-' + match[2] + '-' + match[3],
                }));
            }
        } else {
            ele = value.split('-').join('').replace(/\D/g, '');
            setValue((prevState) => ({
                ...prevState,
                [name]: ele,
            }));
        }
    };

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    const loginApi = async (e) => {
        try {
            const res = await axios.post('https://astrocall.live/api/v1/customer-login', { contact: value.contact });
            console.log(res, 'res')
            if (res.data.success) {
                setOtpStatus(true);
                setOtp(otp);
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const OtpVerify = async (e) => {
        const val = { "contact": value.contact, "otp": useotp }
        const res = await axios.post('https://astrocall.live/api/v1/verify-otp', val);
        console.log(res, 'res')
        if (res.data.status) {
            toastifySuccess(res.data.message);
            setotppage(true)
        } else {
            console.log("error")
        }
    }

    return (
        <>
          
            <nav className="navbar navbar-expand-lg navbar-light ">
                <div className="container-fluid">
                    <Link to={'/'} className='p-2'>
                        <img src="/images/logo.png" />
                    </Link>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon" />
                    </button>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav mx-auto mb-2 mb-lg-0 header-menu">
                            <li className="nav-item">
                                <Link to={'/'} className={`${page === '/' && "active"}`}>Home</Link>
                            </li>
                            {/* <li className="nav-item">
                                <Link to={'/about'} className={`${page === '/about' && "active"}`}>About</Link>
                            </li> */}
                            <li className="nav-item">
                                <Link to={'/horoscope/aries'} className={`${horoscope === 'horoscope' && "active"}`}>Horoscope</Link>
                            </li>
                            {/* <li className="nav-item">
                                <Link to={'/service'} className={`${page === '/services' && "active"}`}>services</Link>
                            </li> */}
                            <li className="nav-item">
                                <Link to={'/plans'} className={`${page === '/plans' && "active"}`}>plans</Link>
                            </li>
                            <li className="nav-item">
                                <Link to={'/kundali'} className={`${page === '/kundali' && "active"}`}>kundali</Link>
                            </li>
                            <li className="nav-item">
                                <Link to={'/astrologers'} className={`${page === '/astrologers' && "active"}`}>astrologers</Link>
                            </li>
                            <li className="nav-item">
                                <Link to={'/astromall'} className={`${astromall === 'astromall' || page === '/astromall' && "active"}`}>astromall</Link>

                            </li>
                            <li className="nav-item">
                                <Link to={'/contact'} className={`${page === 'contact' || page === '/contact' && "active"}`}>contact us</Link>

                            </li>
                        </ul>
                        <div className="logo mt-1">
                            <button className='c-btn' onClick={() => setLoginModal(true)}>sign in / sign up</button>
                        </div>
                    </div>
                </div>
            </nav>
            {/* <div className="top-header bg-white" >
                <div className="header-menu">
                    <div className="header-logo">
                        <div className="container d-flex justify-content-between align-items-center">
                            <div className="logo">
                                <img src="/images/logo.png" />
                            </div>
                        </div>
                    </div>
                    <div className="container">
                        <Link to={'/'} className={`${page === '/' && "active"}`}>home</Link>
                        <Link to={'/about'} className={`${page === '/about' && "active"}`}>about</Link>
                        <Link to={'/horoscope/aries'} className={`${horoscope === 'horoscope' && "active"}`}>horoscope</Link>
                        <Link to={'#'} className={`${page === '/services' && "active"}`}>services</Link>
                        <Link to={'/plans'} className={`${page === '/plans' && "active"}`}>plans</Link>
                        <Link to={'/kundali'} className={`${page === '/kundali' && "active"}`}>kundali</Link>
                        <Link to={'/astrologers'} className={`${page === '/astrologers' && "active"}`}>astrologers</Link>
                        <Link to={'/astromall'} className={`${astromall === 'astromall' || page === '/astromall' && "active"}`}>astromall</Link>
                        {/* <Link to={'#'} className={`${page === '/blog' && "active"}`}>blog</Link> */}
            {/* </div>/ */}

            {/* <div className="logo">
                        <button className='c-btn' onClick={() => setLoginModal(true)}>sign in / sign up</button>
                    </div> */}
            {/* <div className="header-logo">
                    <div className="container d-flex justify-content-between align-items-center">
                        <div className="logo">
                            <img src="/images/logo.png" />
                        </div>
                        <div className="logo">
                            <button className='c-btn' onClick={() => setLoginModal(true)}>sign in / sign up</button>
                        </div>
                    </div>
                </div> */}
            <div className="fixed-icons bs">
                <Link to={'/'} data-bs-toggle="tooltip" data-bs-placement="top" title="Chat With Astrologers"><img src="/images/phone-call.png" className='img-fluid' /></Link>
                <Link to={'/'}><img src="/images/chat.png" className='img-fluid' /></Link>
            </div>
            {
                loginModal &&
                <div id="c-modal">
                    <div className="c-modal-body py-3">
                        <div className="c-modal-header d-flex justify-content-between px-2">
                            <h4>Login</h4>
                            <h4 onClick={() => setLoginModal(false)} style={{ cursor: "pointer" }}><i className='fa fa-times'></i></h4>
                        </div>
                        <hr className='mt-0' />
                        <div className='text-center'>
                            <span >You will receive a 6 digit code for verification</span>
                        </div>
                        <div className="contact-input-box mt-3">
                            <input
                                type="text"
                                className='form-control'
                                name='contact'
                                placeholder='Enter Mobile Number'
                                value={value.contact}
                                onChange={handleChange}
                                maxLength={10}
                            />
                            <button className='c-btn w-100 mt-3' onClick={loginApi} >SEND OTP</button>

                            {otpStatus && (
                                <div>
                                    <span >Enter 6 digit code </span>
                                    <OTPInput
                                        value={useotp}
                                        onChange={setuseotp}
                                        numInputs={6}
                                        separator={<span>- -</span>}
                                        inputStyle={{ width: '3.5rem', height: '3.5rem' }}
                                        isInputNum
                                        renderInput={(props) => <input {...props} />}
                                    />
                                    <br />
                                    <button className='c-btn w-100 mt-3' onClick={OtpVerify} >Verify OTP</button>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            }
            <ToastContainer />
        </>
    )
}

export default Header