import React from 'react'

const PageBanner = ({img}) => {
    return (
        <>
            <div className="page-img-banner mt-3 pb-3">
                <img src={img} className='img-fluid' />
            </div>
        </>
    )
}

export default PageBanner