import { Route, Routes } from 'react-router-dom';
import './App.css';
import Front from './Front';
import Home from './Components/Front/Pages/Home/Home';
import AstrologersList from './Components/Front/Pages/Astrologers/AstrologersList';
import About from './Components/Front/Pages/About/About';
import Plans from './Components/Front/Pages/Plans/Plans';
import Astromall from './Components/Front/Pages/Astromall/Astromall';
import CategoryPage from './Components/Front/Pages/Astromall/CategoryPage';
import Horoscope from './Components/Front/Pages/Horoscope/Horoscope';
import Login from './Components/Front/Login';
import Kundali from './Components/Front/Pages/Kundali/Kundali';
import Contact from './Components/Front/Pages/Contact/Contact';
import Service from './Components/Front/Pages/Service/Service';



// import 'primereact/resources/primereact.css';
// import 'primereact/resources/themes/lara-light-indigo/theme.css';
function App() {
  return (
    <Routes>
        {/* <Route path='/' element={<Login />} /> */}
        <Route path='/' element={<Front cmp = {Home} />} />
        <Route path='/astrologers' element={<Front cmp = {AstrologersList} />} />
        <Route path='/about' element={<Front cmp = {About} />} />
        <Route path='/plans' element={<Front cmp = {Plans} />} />
        <Route path='/astromall' element={<Front cmp = {Astromall} />} />
        <Route path='/astromall/:page' element={<Front cmp = {CategoryPage} />} />
        <Route path='/horoscope/:horoscope' element={<Front cmp = {Horoscope} />} />
        <Route path='/Kundali' element={<Front cmp = {Kundali} />} />
        <Route path='/contact' element={<Front cmp= {Contact} />} />
        <Route path='/service' element={<Front cmp= {Service} />} />

    </Routes>
  );
}

export default App;
