import React, { useState } from 'react';

const Contact = () => {
	const [ formData, setFormData ] = useState({
		name: '',
		email: '',
		phone: '',
		city: '',
		message: ''
	});
	const [ msg, setMsg ] = useState('');

	const handleChange = (e) => {
		const { name, value } = e.target;
		setFormData({
			...formData,
			[name]: value
		});
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		try {
			const response = await fetch('/api/contact', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json'
				},
				body: JSON.stringify(formData)
			});
			const result = await response.json();
			setMsg(result.msg);
		} catch (error) {
			setMsg('Sorry, something went wrong. Please try again later.');
		}
	};

	return (
		<div className="container">
			<header>
				<h1>Contact Us</h1>
			</header>

			<div className="contactus">
				<h2>Get in Touch</h2>
				<div className="row mt-4">{/* Your contact information blocks */}</div>
				<div className="row">
					<div className="col-xl-6">
						<div id="map">
							<iframe
								src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3559.538285745908!2d75.76456411451936!3d26.854633969053978!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x396db4fbf1801bd1%3A0xaa455bfc5250efb5!2sArustu%20Technology%20Pvt.%20Ltd.!5e0!3m2!1sen!2sin!4v1616665214774!5m2!1sen!2sin"
								width="100%"
								height="350"
								frameborder="0"
								style={{ border: '0' }}
								allowfullscreen=""
								aria-hidden="false"
								tabindex="0"
							/>
						</div>
					</div>
					<div className="col-xl-6">
						<div className="contactForm">
							<h3>Feel Free To Contact</h3>
							<form onSubmit={handleSubmit}>
								<div className="row">
									<div className="col-xl-6">
										<div className="form-group">
											<label>Name</label>
											<input
												type="text"
												name="name"
												placeholder="Name"
												className="form-control"
												value={formData.name}
												onChange={handleChange}
											/>
										</div>
									</div>
									<div className="col-xl-6">
										<div className="form-group">
											<label>Email</label>
											<input
												type="email"
												name="email"
												placeholder="Email"
												className="form-control"
												value={formData.email}
												onChange={handleChange}
											/>
										</div>
									</div>
								</div>
								<div className="row">
									<div className="col-xl-6">
										<div className="form-group">
											<label>Phone</label>
											<input
												type="text"
												maxlength="10"
												name="phone"
												placeholder="Phone"
												className="form-control"
												value={formData.phone}
												onChange={handleChange}
											/>
										</div>
									</div>
									<div className="col-xl-6">
										<div className="form-group">
											<label>City</label>
											<input
												type="text"
												name="city"
												placeholder="City"
												className="form-control"
												value={formData.city}
												onChange={handleChange}
											/>
										</div>
									</div>
								</div>
								<div className="row">
									<div className="col-xl-12">
										<div className="form-group">
											<label htmlFor="">Message</label>
											<textarea
												name="message"
												placeholder="Enter message..."
												rows="3"
												className="form-control textarea"
												value={formData.message}
												onChange={handleChange}
											/>
										</div>
									</div>
								</div>
								<center>
									<button type="submit" className="">
										Submit
									</button>
								</center>
							</form>
							{msg && <p>{msg}</p>}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Contact;
