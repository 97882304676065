import React, {createContext, useState } from 'react'
import { instance } from '../../api'

export const CommonContextData = createContext()
const CommonContext = ({ children }) => {
    const [dataList, setDataList] = useState([])
    const [loading, setLoading] = useState(false)

    // const getDataUtility = async () => {
    //     try {
    //         const res = await axios.post(url + "/utility.php", { "type": "get-data-utility" })
    //         if (res.data.status) setUtilityObj(res?.data?.data); else setUtilityObj()
    //     } catch (error) { console.log(error) }
    // }

    const getDataFun = async (endPoint) => {
        setLoading(true)
        try {
            const res = await instance.get(endPoint)
            if (res?.data?.status) {
                setDataList(res?.data?.data)
                setLoading(false)
            } else {
                setDataList([])
                setLoading(false)
            }
        } catch (error) { 
            setDataList()
            console.log(error)
            setLoading(false)
         }
    }
    return (
        <CommonContextData.Provider value={{getDataFun, dataList, loading}}>
            {children}
        </CommonContextData.Provider>
    )
}

export default CommonContext